import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { ImWhatsapp } from "react-icons/Im"
import { ImMail2 } from "react-icons/Im"
import contactStyles from "./contact.module.scss"

const ContactPage = () => {
  return (
    <Layout title="Contact" btnText="" btnLink="">
      <Head title="Contact" />
      <section>
        <wrapper>
          <h3>Have a question or just want to say hi?</h3>
          <p>You can text us on:</p>
          <div className={contactStyles.contact__info}>
            <ImMail2 /> <a href="mailto:info@kreso.org">info@kreso.org</a>
          </div>
          <div className={contactStyles.contact__info}>
            <ImWhatsapp />
            <a href="https://wa.me/38598669565">+385 98 669 565</a>
          </div>
        </wrapper>
      </section>
    </Layout>
  )
}

export default ContactPage
